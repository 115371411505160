type CalloutProps = {
    emoji?: string
    text?: string
    children?: React.ReactNode
}

export default function Callout({ emoji, text, children }: CalloutProps) {
    return (
        <div className="rounded bg-base text-base-foreground text-md flex items-start p-3 my-6">
            <span className="block w-6 text-center mr-3 scale-[1.2]">
                {emoji}
            </span>
            <span className="block grow">{text ?? children}</span>
        </div>
    )
}
