'use client'

import type { ReactNode } from 'react'
import Balancer from 'react-wrap-balancer'

import { cn } from '@/lib/utils'
import { withHeadingId } from './utils'

type H2Props = {
    isGradient?: boolean
    className?: string
    children: ReactNode
}

export default function H2({
    children,
    className,
    isGradient = false,
    ...props
}: H2Props) {
    return (
        <h2 className={cn({ 'text-gradient': isGradient }, className)} {...props}>
            <Balancer>{withHeadingId(children)}</Balancer>
        </h2>
    )
}
