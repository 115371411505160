'use client'

import * as React from 'react'

export type SectionProps = {
    title?: string
    children: React.ReactNode
}

const ListSection: React.FC<SectionProps> = ({ title, children }) => {
    return (
        <section role="group" aria-labelledby={title} className="my-6">
            <h3
                id={title}
                className="font-mono text-sm text-base-foreground mb-2 mx-[2px]"
            >
                {'//' + ' ' + title}
            </h3>
            <div role="list">{children}</div>
        </section>
    )
}

export default ListSection
