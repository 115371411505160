import NextImage, { ImageProps as NextImageProps } from 'next/image'

type ImageProps = NextImageProps & {
    alt: string
    className?: string
}

export default async function Image({ alt, className, ...props }: ImageProps) {
    return (
        <NextImage alt={alt} className={`rounded-lg ${className}`} {...props} />
    )
}
