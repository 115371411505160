'use client'

import Balancer from 'react-wrap-balancer'
import type { ReactNode } from 'react'

export default function Caption({ children }: { children: ReactNode }) {
    return (
        <span className="block w-full text-xs my-3 font-mono text-base-foreground text-center leading-normal">
            <Balancer>
                <span className="[&>a]:post-link">{children}</span>
            </Balancer>
        </span>
    )
}
