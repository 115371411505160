'use client'

import type { ReactNode } from 'react'

type PProps = {
    children: ReactNode
    className?: string
}

export default function P({ className, children, ...props }: PProps) {
    return (
        <p {...props} className={`my-5 [blockquote_&]:my-2 ${className}`}>
            {children}
        </p>
    )
}
