'use client'

import type { ReactNode } from 'react'

import A from './a'
import P from './p'

type FootNotesProps = {
    id: string
    children: ReactNode
    note: string
}

export const FootNotes = ({ children }: { children: ReactNode }) => (
    <div className="font-mono text-base-foreground before:w-[200px] before:m-auto before:content[''] before:border-t before:border-base before:block before:my-10">
        {children}
    </div>
)

export const Ref = ({ id }: { id: string }) => (
    <a
        href={`#f${id}`}
        id={`s${id}`}
        className="relative text-xs top-[-5px] no-underline text-base-foreground"
    >
        [{id}]
    </a>
)

export const FootNote = ({ id, note }: FootNotesProps) => (
    <P className="">
        {id}.{' '}
        <A href={`#s${id}`} id={`f${id}`} className="no-underline">
            ↑
        </A>{' '}
        {note}
    </P>
)
