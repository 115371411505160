import A from './a'
import Blockquote from './blockquote'
import Callout from './callout'
import H1 from './h1'
import H2 from './h2'
import H3 from './h3'
import HR from './hr'
import P from './p'
import Caption from './caption'
import Code from './code'
import { FootNotes, FootNote, Ref } from './footnotes'
import { ProsCard, ConsCard } from './pros-cons-card'
import Image from './image'
import Timeline from './timeline'
// import Tweet from './tweet'
import UL from './ul'
import OL from './ol'
import LI from './li'

const components = {
    a: A,
    blockquote: Blockquote,
    Callout,
    Caption,
    Code,
    code: Code,
    h1: H1,
    h2: H2,
    h3: H3,
    hr: HR,
    Image,
    img: Image,
    p: P,
    ProsCard,
    ConsCard,
    Timeline,
    // Tweet,
    FootNotes,
    FootNote,
    Ref,
    ul: UL,
    ol: OL,
    li: LI,
}

export default components
