import { ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { parseISO, format, formatDistanceToNow } from 'date-fns'

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export function formatDate(input: string): string {
    const parsedDate = safeParseDate(input)
    if (!parsedDate) {
        return 'Invalid Date'
    }
    return format(parsedDate, 'MMMM d, yyyy')
}

export function formatDateConcisely(input: string): string {
    const parsedDate = safeParseDate(input)
    if (!parsedDate) {
        return 'Invalid Date'
    }

    const relativeDate = formatDistanceToNow(parsedDate, { addSuffix: true })
    const conciseRelativeDate = relativeDate
        .replace(/about /, '')
        .replace(/less than a /, '')
        .replace(/almost /, 'almost ')
        .replace(/over /, '')
        .replace(/(\d+)\syears?/, '$1y')
        .replace(/(\d+)\smonths?/, '$1mo')
        .replace(/(\d+)\sdays?/, '$1d')
        .replace(/(\d+)\sminutes?/, '$1m')
        .replace(/(\d+)\sseconds?/, '$1s')

    const fullDate = format(parsedDate, 'MMMM d, yyyy')
    return `${fullDate} (${conciseRelativeDate})`
}

export function safeParseDate(dateString: string) {
    try {
        return parseISO(dateString)
    } catch (error) {
        console.error('Invalid date:', dateString)
        return null
    }
}

export function getMonth(dateString: string): string {
    const date = safeParseDate(dateString)
    if (!date) {
        return ''
    }
    return format(date, 'MMM')
}

export function getDay(dateString: string): number {
    const date = safeParseDate(dateString)
    if (!date) {
        return 0
    }
    return parseInt(format(date, 'd'), 10)
}
